/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultAdminNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Cadastro de cliente',
        type : 'group',
        icon : 'heroicons_outline:finger-print',
        children : [
            {
                id   : 'cadastro.embarcadora',
                title: 'Cadastro de embarcadora',
                type : 'group',
                icon : 'heroicons_outline:office-building',
                children: [
                    {
                        id   : 'embarcadora',
                        title: 'Embarcadora',
                        type : 'basic',
                        icon : 'heroicons_outline:office-building',
                        link : '/cadastro/embarcadora'
                    }
                ]
            }
        ]
    },
    {
        id   : 'report',
        title: 'Relatório',
        subtitle: 'Relatório de cliente',
        type : 'group',
        icon : 'mat_outline:description',
        children : [
            {
                id   : 'report.fechamento',
                title: 'fechamento',
                type : 'basic',
                icon : 'mat_outline:payments',
                link : '/administration/closure'
            }
        ]
    }

];
export const compactAdminNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Cadastro de cliente',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'report',
        title: 'Relatório',
        subtitle: 'Relatório de cliente',
        type : 'aside',
        icon : 'mat_outline:description',
        children : []
    }
];
export const futuristicAdminNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Cadastro de cliente',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'report',
        title: 'Relatório',
        subtitle: 'Relatório de cliente',
        type : 'aside',
        icon : 'mat_outline:description',
        children : []
    }
];
export const horizontalAdminNavigation: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Cadastro de cliente',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'report',
        title: 'Relatório',
        subtitle: 'Relatório de cliente',
        type : 'aside',
        icon : 'mat_outline:description',
        children : []
    }
];
