import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import { defaultOperationNavigation, futuristicOperationNavigation, horizontalOperationNavigation } from './data-operation';
import { compactClientNavigation, defaultClientNavigation, futuristicClientNavigation, horizontalClientNavigation } from './data-client';
import { compactAdminNavigation, defaultAdminNavigation, futuristicAdminNavigation, horizontalAdminNavigation } from './data-admin';
import { compactClientAutonomousNavigation, defaultClientAutonomousNavigation, futuristicClientAutonomousNavigation, horizontalClientAutonomousNavigation } from './data-client-autonomous';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi
{
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    private readonly _compactAdminNavigation: FuseNavigationItem[] = compactAdminNavigation;
    private readonly _defaultAdminNavigation: FuseNavigationItem[] = defaultAdminNavigation;
    private readonly _futuristicAdminNavigation: FuseNavigationItem[] = futuristicAdminNavigation;
    private readonly _horizontalAdminNavigation: FuseNavigationItem[] = horizontalAdminNavigation;

    private readonly _compactClientNavigation: FuseNavigationItem[] = compactClientNavigation;
    private readonly _defaultClientNavigation: FuseNavigationItem[] = defaultClientNavigation;
    private readonly _futuristicClientNavigation: FuseNavigationItem[] = futuristicClientNavigation;
    private readonly _horizontalClientNavigation: FuseNavigationItem[] = horizontalClientNavigation;

    private readonly _compactClientAutonomousNavigation: FuseNavigationItem[] = compactClientAutonomousNavigation;
    private readonly _defaultClientAutonomousNavigation: FuseNavigationItem[] = defaultClientAutonomousNavigation;
    private readonly _futuristicClientAutonomousNavigation: FuseNavigationItem[] = futuristicClientAutonomousNavigation;
    private readonly _horizontalClientAutonomousNavigation: FuseNavigationItem[] = horizontalClientAutonomousNavigation;

    private readonly _compactOperationNavigation: FuseNavigationItem[] = defaultOperationNavigation;
    private readonly _defaultOperationNavigation: FuseNavigationItem[] = defaultOperationNavigation;
    private readonly _futuristicOperationNavigation: FuseNavigationItem[] = futuristicOperationNavigation;
    private readonly _horizontalOperationNavigation: FuseNavigationItem[] = horizontalOperationNavigation;

    constructor(
        private _fuseMockApiService: FuseMockApiService,
    )
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    registerHandlers(): void
    {
        // Menu de Admin
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactNavigation),
                        default   : cloneDeep(this._defaultNavigation),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation)
                    }
                ];
        });
        // Menu de Admin
        this._fuseMockApiService.onGet('api/common/navigation/admin').reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactAdminNavigation.forEach((compactNavItem) => {
                    this._defaultAdminNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicAdminNavigation.forEach((futuristicNavItem) => {
                    this._defaultAdminNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalAdminNavigation.forEach((horizontalNavItem) => {
                    this._defaultAdminNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactAdminNavigation),
                        default   : cloneDeep(this._defaultAdminNavigation),
                        futuristic: cloneDeep(this._futuristicAdminNavigation),
                        horizontal: cloneDeep(this._horizontalAdminNavigation)
                    }
                ];
        });
        // Menu de client
        this._fuseMockApiService.onGet('api/common/navigation/client').reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactClientNavigation.forEach((compactNavItem) => {
                    this._defaultClientNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicClientNavigation.forEach((futuristicNavItem) => {
                    this._defaultClientNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalClientNavigation.forEach((horizontalNavItem) => {
                    this._defaultClientNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactClientNavigation),
                        default   : cloneDeep(this._defaultClientNavigation),
                        futuristic: cloneDeep(this._futuristicClientNavigation),
                        horizontal: cloneDeep(this._horizontalClientNavigation)
                    }
                ];
        });
        // Menu de client Autonomous
        this._fuseMockApiService.onGet('api/common/navigation/client-autonomous').reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactClientAutonomousNavigation.forEach((compactNavItem) => {
                    this._defaultClientAutonomousNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicClientAutonomousNavigation.forEach((futuristicNavItem) => {
                    this._defaultClientAutonomousNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalClientAutonomousNavigation.forEach((horizontalNavItem) => {
                    this._defaultClientAutonomousNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactClientAutonomousNavigation),
                        default   : cloneDeep(this._defaultClientAutonomousNavigation),
                        futuristic: cloneDeep(this._futuristicClientAutonomousNavigation),
                        horizontal: cloneDeep(this._horizontalClientAutonomousNavigation)
                    }
                ];
        });
        // Menu de operador
        this._fuseMockApiService.onGet('api/common/navigation/operation').reply(() => {

                // Fill compact navigation children using the default navigation
                this._compactOperationNavigation.forEach((compactNavItem) => {
                    this._defaultOperationNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicOperationNavigation.forEach((futuristicNavItem) => {
                    this._defaultOperationNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalOperationNavigation.forEach((horizontalNavItem) => {
                    this._defaultOperationNavigation.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactOperationNavigation),
                        default   : cloneDeep(this._defaultOperationNavigation),
                        futuristic: cloneDeep(this._futuristicOperationNavigation),
                        horizontal: cloneDeep(this._horizontalOperationNavigation)
                    }
                ];
        });
    }
}
