import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination } from 'app/core/pagination/pagination.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MovimentOperatorService {

  private api = environment.api_backend;

  private _operationMoviment: BehaviorSubject<any | boolean> = new BehaviorSubject(false);
  private _operationMoviments: BehaviorSubject<any | null> = new BehaviorSubject([]);
  set operationMoviment$(order) { this._operationMoviment.next(order); }
  get operationMoviment$(): Observable<any> { return this._operationMoviment.asObservable(); }
  get operationMoviments$(): Observable<any> { return this._operationMoviments.asObservable(); }

  private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);
  get pagination$(): Observable<IPagination> { return this._pagination.asObservable(); }

  constructor(private _http: HttpClient) { }

  list(page: number = 0, size: number = 50, sort: string = 'created_at', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
  Observable<{ meta: IPagination; data: any[] }>
  {
    return this._http.get<any>(this.api + '/order-operation/moviment', {
        params: { page: '' + page, size: '' + size, sort, order, search }
    }).pipe(
      tap((response) => {
        const dados = response.data;
        const pagination: IPagination = {
          length: dados.total,
          size: dados.per_page,
          page: dados.current_page,
          lastPage: dados.last_page,
          startIndex: 0,
          endIndex: 0
        };
        this._pagination.next(pagination);
        this._operationMoviments.next(dados);
    })
    );
  }

  public startOperation(orderStorageId: string): Observable<unknown>
  {
    return this._http.get(this.api + `/order-operation/moviment/start/${orderStorageId}`).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public storeLocation(orderStorageId: string, virtualStorageId: string, storageActual: any): Observable<any>
  {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = { params : {quantity_rest: storageActual.quantity_rest, quantity_actual: storageActual.quantity_actual} };
    return this._http.get(this.api + `/order-operation/moviment/location/${orderStorageId}/${virtualStorageId}`, params).pipe(
      tap(res => res),
      map(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public finishOperation(orderStorageId: string): Observable<any>
  {
    return this._http.get(this.api + `/order-operation/moviment/finish/${orderStorageId}`).pipe(
      tap(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }
}
