import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { assign, cloneDeep } from 'lodash';
import { STORAGE } from './data';

@Injectable({
    providedIn: 'root'
})
export class StorageApi
{
    private _storages: any = STORAGE;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {
        // GET
        this._fuseMockApiService.onGet('api/common/warehouse/storage').reply(() => {
            if (localStorage.getItem('storages') !== undefined) {
                const dt = JSON.parse(localStorage.getItem('storages'));
                this._storages = dt;
            }
            return [200, cloneDeep(this._storages)];
        });
        // POST
        this._fuseMockApiService.onPost('api/common/warehouse/storage').reply(({request}) => {
            const newStorage = cloneDeep(request.body);
            // Generate a new GUID
            // newNotification.id = FuseMockApiUtils.guid();
            this._storages.unshift(newStorage);
            const dt = this._storages;
            localStorage.setItem('storages', JSON.stringify(dt));

            return [200, newStorage];
        });
        // PUT
        this._fuseMockApiService.onPatch('api/common/warehouse/storage').reply(({request}) => {

            const local = request.body.local;
            const street = request.body.street;
            const section = request.body.section;
            const armazem = cloneDeep(request.body.storages);

            let updatedNotification = null;

            this._storages.forEach((item: any, index: number, storages: any[]) => {

                if (
                    item.local === local &&
                    item.street === street &&
                    item.section === section
                )
                {
                    storages[index] = assign({}, storages[index], armazem);
                    updatedNotification = storages[index];
                }
            });
            localStorage.setItem('storages', JSON.stringify(this._storages));
            return [200, updatedNotification];
        });
        // SEARCH
        this._fuseMockApiService.onPatch('api/common/warehouse/storage/search').reply(({request}) => {
            const local = request.body.local;
            const street = request.body.street;
            const section = request.body.section;
            const index = this._storages.findIndex((item: any) => item.local === local && item.street === street && item.section === section);
            if (index > -1) {
                return [200, this._storages[index]];
            } else {
                return [400, 'Nenhum registro encontrado'];
            }
        });
        // DELETE
        this._fuseMockApiService.onDelete('api/common/warehouse/storage').reply(({request}) => {
            const id = request.params.get('id');
            let deletedStorage = null;
            const index = this._storages.findIndex((item: any) => item.id === id);
            deletedStorage = cloneDeep(this._storages[index]);
            this._storages.splice(index, 1);
            localStorage.setItem('storages', JSON.stringify(this._storages));
            return [200, deletedStorage];
        });

    }
}
