import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination } from 'app/core/pagination/pagination.types';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageOperatorService
{
  private api = environment.api_backend;

  private _orders: BehaviorSubject<any> = new BehaviorSubject([]);
  private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);
  private _operationStorage: BehaviorSubject<any | boolean> = new BehaviorSubject(false);
  private _operationStorages: BehaviorSubject<any | null> = new BehaviorSubject([]);

  set operationStorage$(order: any) { this._operationStorage.next(order); }
  get operationStorage$(): Observable<any> { return this._operationStorage.asObservable(); }

  get operationStorages$(): Observable<any> { return this._operationStorages.asObservable(); }
  get pagination$(): Observable<IPagination> { return this._pagination.asObservable(); }
  set _orders$(w: any) { this._orders.next(w); }
  get _orders$(): Observable<any> { return this._orders.asObservable(); }

  constructor(
    private _http: HttpClient
  ) { }

  list(page: number = 0, size: number = 50, sort: string = 'created_at', order: 'asc' | 'desc' | '' = 'asc', search: string = ''):
  Observable<{ meta: IPagination; data: any[] }>
  {
    return this._http.get<any>(this.api + '/order-operation/storage', {
        params: { page: '' + page, size: '' + size, sort, order, search }
    }).pipe(
      tap((response) => {
        const dados = response.data;
        const pagination: IPagination = {
          length: dados.total,
          size: dados.per_page,
          page: dados.current_page,
          lastPage: dados.last_page,
          startIndex: 0,
          endIndex: 0
        };
        this._pagination.next(pagination);
        this._orders.next(dados);
    })
    );
  }

  public startOperation(orderStorageId: string): Observable<unknown>
  {
    return this._http.get(this.api + `/order-operation/storage/start/${orderStorageId}`).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public storeLocation(orderStorageId: string, virtualStorageId: string, storageActual: any): Observable<any>
  {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = { params : {quantity_rest: storageActual.quantity_rest, quantity_actual: storageActual.quantity_actual} };
    return this._http.get(this.api + `/order-operation/storage/location/${orderStorageId}/${virtualStorageId}`, params).pipe(
      tap(res => res),
      map(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public finishOperation(orderStorageId: string): Observable<any>
  {
    return this._http.get(this.api + `/order-operation/storage/finish/${orderStorageId}`).pipe(
      tap(() => {
        const orders = cloneDeep(this._orders.value);
        const idx = orders.findIndex((e: any) => e.id === orderStorageId);
        if (idx > -1) {
          orders.splice(idx, 1);
        }
        this._orders.next(orders);
      }),
      map((res: any) => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }
}
