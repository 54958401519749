import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';
import { ufs } from './data';

@Injectable({
    providedIn: 'root'
})
export class UfApi
{
    private _ufs: any = ufs;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {

        this._fuseMockApiService.onGet('api/common/estados').reply(() => [200, cloneDeep(this._ufs)]);

    }
}
