import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { assign, cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class PickingApi
{
    private _pickings: any = [];

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {
        // GET
        this._fuseMockApiService.onGet('api/common/warehouse/picking').reply(() => {
            if (localStorage.getItem('pickings') !== undefined) {
                const dt = JSON.parse(localStorage.getItem('pickings'));
                this._pickings = dt;
            }
            return [200, cloneDeep(this._pickings)];
        });
        // POST
        this._fuseMockApiService.onPost('api/common/warehouse/picking').reply(({request}) => {
            const newStorage = cloneDeep(request.body);
            // Generate a new GUID
            // newNotification.id = FuseMockApiUtils.guid();
            this._pickings.unshift(newStorage);
            const dt = this._pickings;
            localStorage.setItem('pickings', JSON.stringify(dt));

            return [200, newStorage];
        });
        // PUT
        this._fuseMockApiService.onPatch('api/common/warehouse/picking').reply(({request}) => {

            const local = request.body.local;
            const street = request.body.street;
            const section = request.body.section;
            const armazem = cloneDeep(request.body.storages);

            let updatedNotification = null;

            this._pickings.forEach((item: any, index: number, storages: any[]) => {

                if (
                    item.local === local &&
                    item.street === street &&
                    item.section === section
                )
                {
                    storages[index] = assign({}, storages[index], armazem);
                    updatedNotification = storages[index];
                }
            });
            localStorage.setItem('pickings', JSON.stringify(this._pickings));
            return [200, updatedNotification];
        });
        // SEARCH
        this._fuseMockApiService.onPatch('api/common/warehouse/picking/search').reply(({request}) => {
            const local = request.body.local;
            const street = request.body.street;
            const section = request.body.section;
            const index = this._pickings.findIndex((item: any) => item.local === local && item.street === street && item.section === section);
            if (index > -1) {
                return [200, this._pickings[index]];
            } else {
                return [400, 'Nenhum registro encontrado'];
            }
        });
        // DELETE
        this._fuseMockApiService.onDelete('api/common/warehouse/picking').reply(({request}) => {
            const id = request.params.get('id');
            let deletedStorage = null;
            const index = this._pickings.findIndex((item: any) => item.id === id);
            deletedStorage = cloneDeep(this._pickings[index]);
            this._pickings.splice(index, 1);
            localStorage.setItem('pickings', JSON.stringify(this._pickings));
            return [200, deletedStorage];
        });

    }
}
