/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
    production: false,
    ambientserver: 'staging',
    version: '4.5.0', // 15/10/2024
    url: 'https://stage.pickingup.com.br',
    api_auth: 'https://auth.cprdigital.com.br/backend/oauth',
    api: 'https://auth.cprdigital.com.br/backend/api',
    api_backend: 'https://stage.pickingup.com.br/backend/api',
    api_mslogger: 'https://stage.pickingup.com.br/mslogger', // Descomentar quando fizer build stage
    // api_mslogger: 'http://localhost:3000',
    client_id: '951103d1-ea10-4649-809d-90d76f5cf3c7',
    client_secret: 'EFGTadB5075yYtYpNfimPbY8ByOsUKq6pDRSMJiD',
    url_document: 'https://sbrcargo.nyc3.digitaloceanspaces.com/blueprints/',
    url_s3: 'https://sbrcargo.nyc3.digitaloceanspaces.com/',
    // Apis de outros servidores
    api_backend_hml: 'https://hml.pickingup.com.br/backend/api',
    api_backend_prod: 'https://prod.pickingup.com.br/backend/api',
};
