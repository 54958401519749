export const ufs: string[] = [
   'AC',
   'AL',
   'AP',
   'AM',
   'BA',
   'CE',
   'ES',
   'GO',
   'MA',
   'MT',
   'MS',
   'MG',
   'PA',
   'PB',
   'PR',
   'PE',
   'PI',
   'RJ',
   'RN',
   'RS',
   'RO',
   'RR',
   'SC',
   'SP',
   'SE',
   'TO',
   'DF'
];
