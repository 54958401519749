/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private api = environment.api_backend;

  private _mappings: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private _storages: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private _locateProduct: BehaviorSubject<any> = new BehaviorSubject(false);
  private _orders: BehaviorSubject<any> = new BehaviorSubject(false);
  private _products: BehaviorSubject<any[]> = new BehaviorSubject(null);
  private _product: BehaviorSubject<any> = new BehaviorSubject(false);
  private _openDetail: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _newSearch: BehaviorSubject<string|boolean> = new BehaviorSubject(false);
  private _selectedSpace: BehaviorSubject<any|boolean> = new BehaviorSubject(false);
  private _locateSpace: BehaviorSubject<any|boolean> = new BehaviorSubject(false);

  private _spaces: BehaviorSubject<any> = new BehaviorSubject(false);
  private _fifo: BehaviorSubject<any> = new BehaviorSubject(false);

  get mappings$(): BehaviorSubject<any[]> { return this._mappings; }
  get storages$(): BehaviorSubject<any[]> { return this._storages; }
  get locateProduct$(): BehaviorSubject<any> { return this._locateProduct; }
  get orders$(): BehaviorSubject<any> { return this._orders; }

  set openDetail$(res: boolean) { this._openDetail.next(res); }
  get openDetail$(): any { return this._openDetail; }

  set locateSpace$(res: any) { this._locateSpace.next(res); }
  get locateSpace$(): any { return this._locateSpace; }

  get products$(): any { return this._products; }
  set product$(product: any) { this._product.next(product); }
  get product$(): any { return this._product; }
  get newSearch$(): any { return this._newSearch; }
  get selectedSpace$(): any { return this._selectedSpace; }

  get spaces$(): any { return this._spaces; }
  get fifo$(): any { return this._fifo; }

  constructor(private _http: HttpClient) {}

  public list(): Observable<any>
  {
    return this._http.get(this.api + '/report/mapping').pipe(
      tap((result: any) => {
        if (result.data) {
          this._mappings.next(result.data);
          this._storages.next(result.data);
        } else {
          this._mappings.next([]);
        }
      }),
      map(res => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public listProducts(parameters: any): Observable<any>
  {
    const params = {
      type: parameters?.type,
      search: parameters?.search
    };
    return this._http.get(this.api + '/report/order-storages', {params}).pipe(
      tap((result: any) => {
        if (result.data) {
          this._products.next(result.data);
        } else {
          this._products.next(null);
        }
      }),
      map(res => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public getOrderFromStorage(storageId: string): Observable<any>
  {
    const storages = cloneDeep(this._storages.value);
    if (storages[storageId].hasOwnProperty('orders') ) {
      this._products.next(storages[storageId].orders);
      return of(storages[storageId].orders);
    } else {
      return this._http.get(this.api + '/report/order-storage/' + storageId).pipe(
        tap((result: any) => {
          storages[storageId]['orders'] = result.data;
          this._storages.next(storages);
          this._products.next(result.data);
          return result.data;
        }),
        map(res => res),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
    }
  }

  public getDetailFromProduct(storageId: string, order: any): Observable<any> {
    const orderStorageId = order.order_storage_id;
    const storages = cloneDeep(this._storages.value);
    const index = storages[storageId].orders.findIndex((el: any) => el.order_id === order.order_id && el.order_storage_id === orderStorageId);
    if (index > -1) {
      const actualOrder = storages[storageId].orders[index];
      if (actualOrder.hasOwnProperty('details')) {
        this._product.next(actualOrder);
        return of(actualOrder.details);
      } else {
        return this._http.get(this.api + '/report/order-storage/detail/' + orderStorageId).pipe(
          tap((result: any) => {
            order['details'] = result.data;
            this._product.next(order);
            this._storages.next(storages);
            return result.data;
          }),
          map(res => res),
          catchError((err: HttpErrorResponse) => throwError(err))
        );
      }
    }
    return of(false);
  }

  public getOrderFromSpace(data: any): void
  {
    const storageId = data.storageId;

    const mapping = cloneDeep(this._mappings.value);
    const sto = mapping[storageId];
    const spaces = sto.objects;

    const actualLevel = data.actualSpace.level;
    const groupname = data.actualSpace.groupname;
    const actualAddress = data.actualSpace.id;
    const spaceDetails = [];
    if (actualLevel !== 'ROOT') {
      const result = spaces.filter((el: any) => el.groupname === groupname && el.id === actualAddress);
      if (result.length > 0) {
        result.sort((a: any, b: any) => a.level > b.level? -1 : 1);
        result.map((item: any) => {
          const virtualStorageId = item.name;
          const group = item.groupname;
          const level = item.level;
          const address = group + ':' + item.id + ':' + level;
          const orders = sto.orders[virtualStorageId];
          spaceDetails.push({virtualStorageId: virtualStorageId, level: level, address:address, orders: orders});
        });
      }
    } else {
      const virtualStorageId = data.actualSpace.name;
      const group = data.actualSpace.groupname;
      const address = group + ':' + data.actualSpace.id + ':Z1';
      const orders = sto.orders[virtualStorageId];
      spaceDetails.push({virtualStorageId: virtualStorageId, level: 'Z1', address:address, orders: orders});
    }
    this._selectedSpace.next(spaceDetails);
    this._openDetail.next(true);
  }

  public openDetailStorageSpace(data: any): void {
    this._selectedSpace.next(data);
    this._openDetail.next(true);
  }

  public getDetailMapStorage(storageId: string): Observable<any> {
    return this._http.get(this.api + `/report/mapping-detail/${storageId}`).pipe(
      map((res: any) => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public locateProductOnMap(data: any): void
  {
    this._locateProduct.next(data);
  }

  public deslocateProductOnMap(): void
  {
    this._locateProduct.next(false);
  }

  public searchProductCode(productCode: string): any
  {
    this._newSearch.next(productCode);
  }

  public searchProduct(storageId: string, product: string, type: number = 99): any
  {
    this._newSearch.next(false);
    let products = cloneDeep(this._products.value);
    if (type !== 99) {
      products = products.filter((el: any) => el.product_type === type);
    }

    if ( product && products)
    {
      const results = products.filter((el: any) => {
        const productCode = (el.product_code).toLowerCase();
        const productName = (el.product_name).toLowerCase();
        return productCode && productCode.includes(product.toLowerCase()) || productName && productName.includes(product.toLowerCase());
      });
      return results;
    }
    return products;
  }

  public downloadCSV(): Observable<any>
  {
    return this._http.get(this.api + '/report/download-csv').pipe(
      map((res: any) => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public downloadJSON(): Observable<any>
  {
    return this._http.get(this.api + '/report/download-json').pipe(
      map((res: any) => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public downloadPickingCSV(): Observable<any>
  {
    return this._http.get(this.api + '/report/picking-order/download-csv').pipe(
      map((res: any) => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public downloadPickingJSON(): Observable<any>
  {
    return this._http.get(this.api + '/report/picking-order/download-json').pipe(
      map((res: any) => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }
  /*****************************************************************
   *                         DASHBOARD                             *
   *****************************************************************/
   public getTotalSpaces(storageId: string): Observable<any>
   {
     return this._http.get(this.api + `/storage/dashboard/total-spaces/${storageId}`).pipe(
       tap((result: any) => {
        this._spaces.next(result.spaces);
        this._products.next(result.products);
       }),
       catchError((err: HttpErrorResponse) => throwError(err))
     );
   }
}
